import React, {useState, useEffect} from "react";

const Image = ({
  photo,
  caption,
  user,
  onClick
}) => {

  const onClickHandler = onClick ? onClick : () => console.warn('no onClick handler for Image')

  const link = photo.user ? ( <div style={{float: "left"}}><a href={`/u/${photo.user}`} target="_blank">/u/{photo.user}</a></div> ) :
      photo.subreddit ? ( <div style={{float: "left"}}><a href={`/r/${photo.subreddit}`} target="_blank">/r/{photo.subreddit}</a></div> )  : (<></>)

  return(
    <div style={{height: "100%", width: photo.width}}>
      <div style={{marginLeft: 10, marginRight: 10}}>
        {link}
        <div style={{float: "right"}}><a href={photo.download} target="_blank" download>download</a> </div>
      </div>

      <img
        src={photo.src}
        height={photo.height}
        width={photo.width}
        onClick={onClickHandler}
        />
      <div style={{marginLeft: 10, marginRight: 10}}>
        <p>

            <div style={{float: "left"}}><a href={photo.permalink}>Original Post</a></div>
            <div style={{float: "right"}}>{photo.dupBlocks}</div>

        </p>
        <br/>
        <p>
          {photo.caption}
        </p>
      </div>
    </div>
  )
}

export default Image