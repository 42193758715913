import logo from './logo.svg';
import './App.css';
import {useParams, useSearchParams} from 'react-router-dom';
import {useEffect, useState, useCallback} from "react";
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from "react-images";
import Image from "./Image";
const uuid = require('uuid')
const originalFetch = require('isomorphic-fetch');
const fetchRT = require('fetch-retry')(originalFetch);

//import { imageHash } from 'image-hash';


function App() {

  const [duplicateCount, setDuplicateCount] = useState(0)
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [images, setImages] = useState([])
  const [links, setLinks] = useState([])
  const [searchParams] = useSearchParams()
  const [after, setAfter] = useState()
  const [loaded, setLoaded] = useState(false)
  const [error, setError] = useState(null)

  const { r } = useParams()
  const { u } = useParams()

  const fingerprintThumbnail = async (url, index) => {

    try {


      console.log(`fingerprinting ${url}`)

      const response = await fetchRT(`https://k77inat6v3.execute-api.us-west-2.amazonaws.com/prod/fingerprint-image`, {
        retryOn: [500],
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          uri: url,
          srcIndex: index,
          requestId: uuid.v4()
        })
      })

      const obj = await response.json();

      console.log(`fingerprint response for ${url} -> ${obj.signature}`)

      return obj.signature
    } catch (e) {
      console.warn(e)
      return undefined
    }
  }

  const fingerprintThumbnailBatch = async (requestObjs) => {

    try {

      console.log(`fingerprinting ${requestObjs}`)

      const response = await fetchRT(`https://k77inat6v3.execute-api.us-west-2.amazonaws.com/prod/fingerprint-image`, {
        retryOn: [500],
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestObjs)
      })

      const obj = await response.json();

      console.log(`fingerprint response for ${requestObjs} -> ${obj}`)

      return obj

    } catch (e) {
      console.warn(e)
      return undefined
    }
  }

  useEffect(() => {
    console.log(`images: ${images.length} after: ${after}`)



    console.log(`${duplicateCount} duplicates removed`)

  }, [images])

  useEffect(() => {
    if (loaded) {
      console.log('setImages')
      console.log(images)

      images.map((img,index) => {
        if (!img.sig) {
          console.log(`noSig: ${index}`)
          console.log(img)
        }
      })
    }
  }, [loaded])

  useEffect(() => {
    console.log('init')
    console.log(images.length)
    if (searchParams) {

      const a = after ? `&after=${after}` : ''


      const url = r ? `https://www.reddit.com/r/${r}.json?sort=new&limit=100${a}` : u ? `https://www.reddit.com/user/${u}.json?limit=100&sort=new${a}` : null

      if (url) {
        console.log(`fetching ${url}`)
        fetchRT(url, {
          retryOn: [503],
          retries: 15,
          redirect: 'manual'
        })
          .then((res) => {
            console.log('fetch then')
            if (!res.ok) {
              setError(res.status)
            } else {
              setError(null)
            }
            return res.json()
          }, err => {
            console.log(`err: ${err}`)
          })
          .then(data => {


            if (data && data.data && data.data.children && data.data.children.length) {

              const expandedGalleryImages = []
              const tempLinks = []

              data.data.children.forEach(child => {

                if (child.kind && child.kind === 't1' && child.data.body && child.data.body.startsWith('http')) {
                  let link = child.data.body
                  const spaceChar = link.indexOf(' ')

                  if (spaceChar !== -1) {
                    link = link.substring(0, spaceChar )
                  }
                  tempLinks.push(link)
                }

                if (child.data.gallery_data && child.data.gallery_data.items) {

                  const galleryImages = child.data.gallery_data.items.map(img => {

                    const media = child.data.media_metadata[img.media_id]

                    if (media.p) {
                      expandedGalleryImages.push({
                        expanded: true,
                        kind: "t3",
                        data: {
                          ...child.data,
                          thumbnail: media.p[media.p.length - 1].u,
                          thumbnail_width: media.p[media.p.length - 1].x,
                          thumbnail_height: media.p[media.p.length - 1].y,
                          url: media.s.u
                        }
                      })
                    }

                  })


                } else {
                  expandedGalleryImages.push(child)
                }
              })

              setLinks([...links, ...tempLinks])

              console.log('exp', expandedGalleryImages)

              let tempImages = expandedGalleryImages.map(child => {


                if (child && child.kind && (child.kind === 't3' || child.kind === 't1') && child.data && child.data.url
                  && child.data.thumbnail && child.data.author && child.data.title && child.data.thumbnail_width && child.data.thumbnail_height) {

                    const url = () => {
                      if (child.data.preview && child.data.preview.images && child.data.preview.images.length && child.data.preview.images[0].source ) {
                        return child.data.preview.images[0].source.url
                      }

                      return child.data.url
                    }

                    const uri = url();


                    let ret = {
                      //thumbnailCaption: (<a href={`/?u=${child.data.author}`}>{child.data.author}</a>),
                      //user: child.data.author,
                      thumbnail: child.data.thumbnail ? child.data.thumbnail.replaceAll('amp;', '') : child.data.thumbnail,
                      src: uri.replaceAll("amp;", ""),
                      caption: child.data.title,
                      thumbnailWidth: child.data.thumbnail_width,
                      thumbnailHeight: child.data.thumbnail_height,
                      permalink: child.data.permalink
                    }

                    if (u && child.data.subreddit && child.data.subreddit !== `u_${child.data.author}`) {
                      ret['subreddit'] = child.data.subreddit
                    } else if (r) {
                      ret['user'] = child.data.author
                    }

                    return ret
                } else {
                  return null
                }
              }, err => {
                console.log(`err2: ${err}`)
              })

              tempImages.map(child => {
                if (child) {
                  if (!child.thumbnail || child.thumbnail === 'spoiler' || child.thumbnail === 'default' || child.thumbnail === 'image') {
                    child.thumbnail = child.src
                  }
                }
              })

              const fingerPrintPromises = []

              tempImages = tempImages.filter(i => (i !== null))

              const BATCH_SIZE = 5

              console.log('tempImages: ', tempImages)
              for (let x = 0; x < tempImages.length; x += BATCH_SIZE) {

                const requestObjs = []

                for (let xx = x; xx < Math.min(tempImages.length, x+BATCH_SIZE); xx++) {
                  requestObjs.push({
                    uri: tempImages[xx].src,
                    index: xx
                  })
                }

                fingerPrintPromises.push( new Promise((resolve, reject) => {
                  fingerprintThumbnailBatch(requestObjs).then(resp => {

                    try {
                      for (const s of resp) {
                        tempImages[s.index]["sig"] = s.signature
                      }
                    } catch (err) {
                      console.log(`error: ${err}`)
                    }
                    resolve()
                  })
                }) )
              }

              // const fingerPrintPromises = tempImages.map((child, index) => {
              //
              //   if (child) {
              //     if (!child.thumbnail || child.thumbnail === 'spoiler' || child.thumbnail === 'default' || child.thumbnail === 'image') {
              //       child.thumbnail = child.src
              //     }
              //
              //     return new Promise((resolve, reject) => {
              //       fingerprintThumbnailBatch([child.src], [index]).then(sig => {
              //         child["sig"] = sig[0]
              //         /*child["siglen"] = sig.length
              //         child["sigint"] = sig.startsWith('unsupported') ? null : BigInt(`0x${sig}`) */
              //
              //         resolve()
              //       })
              //     })
              //   } else {
              //     return null
              //   }
              // })

              Promise.all(fingerPrintPromises).then(() => {

                const tempImages2 = [...images]

                for (const ti of tempImages) {

                  if (!ti) {
                    continue
                  }

                  if (!ti.sig) {
                    tempImages2.push({
                      ...ti,
                      dupBlocks: -1
                    })
                    continue
                  }

                  let insert = true
                  let highestDup = null

                  for (const i of tempImages2) {

                    let dupBlocks = 0

                    if ((i.thumbnail === ti.thumbnail) || (i.src === ti.src) ){
                      insert = false
                      setDuplicateCount(duplicateCount+1)
                      break
                    }

                    if (i.sig) {

                      const a1 = [...ti.sig]
                      const a2 = [...i.sig]

                      for (let ix = 0; ix < Math.min(a1.length, a2.length); ix += 2){
                        dupBlocks += a1[ix] === a2[ix]  && a1[ix+1] === a2[ix+1] ? 1 : 0
                      }
                    }

                    if (dupBlocks >= 16) {
                      insert = false
                      setDuplicateCount(duplicateCount+1)
                      break
                    }

                    highestDup = highestDup === null ? dupBlocks : Math.max(highestDup, dupBlocks)
                  }

                  if (insert) {
                   // tempImages2.push(ti)
                    tempImages2.push({
                      ...ti,
                    dupBlocks: highestDup
                    })
                  }

                }

                setImages([...tempImages2])
                //setImages([...images, ...tempImages2])

                if (data.data.after) {
                  setAfter(data.data.after)
                  setLoaded(false)
                } else {
                  setLoaded(true)
                }

              })



            }
          })
       }
    } else {
      console.log('null searchParams')
    }


  }, [after])

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const imageRenderer = useCallback(
    ({ index, left, top, key, photo }) => (
      <Image
        key={key}
        index={index}
        photo={photo}
        left={left}
        top={top}
        onClick={() => {
          console.log(`onclick ${index}`)
          openLightbox(null, {photo, index})
        }}
      />
    ),
    []
  );


    if (loaded) {


      const srcUrl = r ? `https://reddit.com/r/${r}` : `https://reddit.com/u/${u}`
      const srcCaption = r ? r : u

      console.log(images)

      let linksMarkup = (<></>)
      let linksItems = null
      let linksMap = {}

      if (links && links.length > 0) {
        const linksItems = links.map(l => {

          if (linksMap[l.toLowerCase()]) {
            return (<></>)
          } else {
            linksMap[l.toLowerCase()] = true
            return (<li><a href={l}>{l}</a></li>)
          }
        })
        linksMarkup = (
            <div>
              <ul>
                {linksItems}
              </ul>
            </div>
        )
      }
      return (
        <div>

          <div>Loaded {images ? images.length : 0} images, {duplicateCount} duplicates removed,  <a href={srcUrl} target="_blank">{srcCaption}</a></div>
          {linksMarkup}
          <Gallery photos={images.map((i, index) => ({
            src: i.thumbnail,
            width: i.thumbnailWidth,
            height: i.thumbnailHeight,
            user: i.user,
            subreddit: i.subreddit,
            caption: `${index} ${i.caption}`,
            download: i.src,
            permalink: `https://reddit.com${i.permalink}`,
            dupBlocks: i.dupBlocks
          }))}  renderImage={imageRenderer}/>
          <ModalGateway>
            {viewerIsOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel
                  currentIndex={currentImage}
                  views={images.map(i => ({
                    source: {
                      regular: i.src,
                      download: i.src
                    },
                    caption: i.caption
                  }))}
                />
              </Modal>
            ) : null}
          </ModalGateway>
          <div><a href={srcUrl} target="_blank">{srcCaption}</a></div>
          {linksMarkup}
        </div>
      );
    } else if (error) {
      return ( <div>Error: {error}</div>)
    } else {
      return( <div>Loaded {images ? images.length : 0} images, {duplicateCount} duplicates removed...</div>
      )
    }

   // loaded ? <Gallery images={images ? images : []} /> : <div>Loaded {images ? images.length : 0} images...</div>

}

export default App;
